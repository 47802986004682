import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from "gatsby";
import Flickity from "react-flickity-component";

import Layout from "../components/layout";
import { BackgroundSection } from "../components/primitives";
import { DisplayHeader } from '../components/composites';
import {GatsbyLink, tagRoute} from "../utils";

import './tag.scss'

const flickityOptions = {
    freeScroll: false,
    prevNextButtons: false,
    pageDots: false,
    imagesLoaded: true,
    cellAlign: 'left'
};


const TagsPage = ({data, location}) => {
    const {
        directus: {
            tags
        }
    } = data;

    return(
        <Layout className='tags-template'
                title='All Categories | Explore Wineries Worldwide'
                currentPage='tags'
                location={location}
                description='Wineries offer so many different experiences check them out'
                image={null}
        >
            <DisplayHeader
                title={'All Categories'}
                location={location}
            >
                <section className='all-tags-section'>
                    <div className='shell'>
                        <div className='section-body'>
                            <ul className='list-search-result'>
                            {tags.map( ({description, id, name, slug, featured_image}, index) => (
                            <li key={`list-item-${id}`}>
                                <div className="search-result-item">
                                    <div className="search-result-item-inner">
                                        <Flickity
                                            className={'search-result-item-slider'}
                                            options={flickityOptions}
                                            reloadOnUpdate={true}
                                        >
                                            <div className="slide" key={index + name}>
                                                <BackgroundSection
                                                    className='slide-image'
                                                    image={featured_image ?? ''}
                                                    isGatsbyImage
                                                >
                                                    <GatsbyLink href={tagRoute(slug)}>
                                                        <span className='sr-only'>View {name}</span>
                                                    </GatsbyLink>
                                                </BackgroundSection>
                                            </div>
                                        </Flickity>
                                    </div>

                                    <div className="search-result-item-content">
                                        <GatsbyLink href={tagRoute(slug)}><span className='sr-only'>View {name}</span></GatsbyLink>
                                        <div className="search-result-item-title">{name}</div>
                                        <p className='search-result-item-text'>{description}</p>
                                    </div>
                                </div>
                            </li>
                            ))}
                            </ul>
                        </div>
                    </div>
                </section>
            </DisplayHeader>
        </Layout>
    )
}

TagsPage.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
}

TagsPage.defaultProps = {

}

export const TagsQuery = graphql`
  query {
    directus {
      tags(
        limit: -1
        sort: ["sort"]
       filter: {status: {_eq: "published"}}
      ) {
        id
        name
        slug
        description
        featured_image {
          id
          imageFile {
              childImageSharp {
                gatsbyImageData
              }
            }
        }
       }
    }
  }
`

export default TagsPage